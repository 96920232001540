import { useRouter } from 'next/router';

import { logger } from '@lib/logger';

export const useCampaignCodeFromUrl = () => {
  const router = useRouter();
  const campaignCodeFromURL =
    router.query['campaign'] || router.query['discount'];

  if (typeof campaignCodeFromURL === 'object') {
    logger.error(
      `Campaign code from URL is an object: ${JSON.stringify(
        campaignCodeFromURL
      )}`,
      null
    );
  }

  return {
    campaignCodeFromURL: campaignCodeFromURL as string,
    isCampaignCodeProvided: typeof campaignCodeFromURL === 'string'
  };
};
