import { useEffect, useMemo, useState } from 'react';

import { GetTemplateResponse, queries } from '@core/api';
import { useMessaging } from '@core/messaging';
import storage from '@core/storage';
import { useTranslation } from '@core/translation';
import { updateUserStore } from '@features/onboarding';
import { logger } from '@lib/logger';
import { useWindow } from '@lib/utils';

export const useTemplateData = (): {
  data: GetTemplateResponse;
  isLoading: boolean;
} => {
  const { hasWindow } = useWindow();

  const { addMessage } = useMessaging();
  const tError = useTranslation('ERROR');
  const updateUser = useMemo(() => updateUserStore('updateUser'), []);

  const [productId, setProductId] = useState<string | undefined>();
  const { useGetTemplate } = queries.quotes;
  const { useGetProducts } = queries.products;
  const { data, isLoading } = useGetTemplate({
    parameters: { productId: productId as string },
    options: {
      enabled: !!productId,
      onError: (error) => {
        addMessage({
          message: tError('UNEXPECTED_ERROR')
        });
        logger.error('Get Template Data Error', error);
      }
    }
  });
  const storageToken = hasWindow ? storage().accessToken : undefined;

  const { data: productData } = useGetProducts({
    options: {
      enabled: !!storageToken,
      onError: (error) => {
        logger.error('Get Products Error', error);
        addMessage({
          message: tError('UNEXPECTED_ERROR')
        });
      }
    }
  });

  useEffect(() => {
    if (productData?.result?.productList[0].productId) {
      setProductId(productData?.result.productList[0].productId);
    }
  }, [productData?.result]);

  useEffect(() => {
    const userInfo = data?.result?.userInfo;
    if (userInfo?.givenName && userInfo?.lastName && userInfo?.dateOfBirth) {
      updateUser({
        givenName: userInfo.givenName,
        lastName: userInfo.lastName,
        birthDate: userInfo.dateOfBirth
      });
    }
  }, [data?.result?.userInfo, updateUser]);

  const templateData = data as GetTemplateResponse;

  return { data: templateData, isLoading };
};
