import React from 'react';

import { useRouter } from 'next/router';

import { Link, SejfaLogo } from '@components/index';

import { AndroidButton, IosButton } from './AppButtons';
import { useLandingPageTranslation } from '../translation';

const Footer = () => {
  const t = useLandingPageTranslation();
  const { asPath, push } = useRouter();
  const isRootPath = asPath === '/';

  const handleNavigateToRoot = () => {
    if (!isRootPath) {
      push('/');
    }
  };

  //TODO: update links and contact
  const data: Array<{
    id: number;
    title: string;
    links: Array<{
      id: string;
      title: string;
      link: string;
      target?: '_blank' | '_self' | undefined;
    }>;
  }> = [
    {
      id: 1,
      title: t('SOCIAL'),
      links: [
        {
          id: 'instagram',
          title: 'Instagram',
          link: 'https://instagram.com/sejfa.nu?igshid=MzRlODBiNWFlZA=='
        }
      ]
    },
    {
      id: 2,
      title: t('IMPORTANT_DOCUMENTS'),
      links: [
        {
          id: 'PRE_SALE',
          title: t('PRE_SALE_INFO'),
          link: '/forkopinformation',
          target: '_blank'
        },
        {
          id: 'PRODUCT_FACT',
          title: t('PRODUCT_FACT'),
          link: '/produktfaktablad',
          target: '_blank'
        },
        {
          id: 'POLICY_AGREEMENT',
          title: t('POLICY_AGREEMENT'),
          link: '/villkor',
          target: '_blank'
        }
      ]
    },
    {
      id: 3,
      title: t('READ_MORE'),
      links: [
        {
          id: 'your_integrity',
          title: t('YOUR_INTEGRITY'),
          link: '/din-integritet',
          target: '_self'
        },
        {
          id: 'terms',
          title: t('TERMS'),
          target: '_self',
          link: '/anvandarvillkor'
        },
        {
          id: 'complaints',
          title: t('COMPLAINTS'),
          target: '_self',
          link: '/klagomal'
        }
      ]
    }
  ];

  return (
    <div className="grid gap-10 px-6 pt-10 md:grid-flow-col md:px-6 md:pt-0">
      <div>
        <SejfaLogo
          onClick={handleNavigateToRoot}
          className={`w-[176px] ${
            isRootPath ? 'cursor-auto' : 'cursor-pointer'
          } md:col-span-1`}
        />
      </div>
      <div className="grid gap-8 md:grid-flow-col">
        {data.map((item) => (
          <div key={item.id} className="space-y-2">
            <label className="text-xl font-medium text-white">
              {item.title}
            </label>
            <div className="grid gap-y-2.5">
              {item.links.map((link) => {
                if (link.id === 'phone' || link.id === 'email') {
                  return (
                    <Link
                      className="no-underline"
                      link={link.link}
                      title={link.title}
                      key={link.id}
                    />
                  );
                }
                return (
                  <Link
                    className="no-underline"
                    key={link.id}
                    title={link.title}
                    link={link.link}
                    target={link.target}
                  />
                );
              })}
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-col pb-12 md:hidden">
        <label className="pb-3 text-xl font-medium text-white">
          {t('DOWNLOAD_APP')}
        </label>
        <IosButton />
        <div className="h-2 w-full" />
        <AndroidButton />
      </div>
    </div>
  );
};

export default Footer;
