import env from '@core/env-config';
import { createQueryStore } from '@lib/query';

import { QueryKey } from './query-definitions';

const { createMutation, createMutationWithMock, createQuery } =
  createQueryStore<QueryKey>({
    useMock: env.USE_MOCK === 'true'
  });

export { createMutation, createMutationWithMock, createQuery };
