import {
  mutations,
  queries,
  type GetTemplateResponse,
  type CalculateQuoteRequest,
  type CheckSimulationStatusResponse,
  type ConvertQuoteToPolicyResponse,
  type HomeData,
  type CheckSimulationStatusRequest,
  type CalculateQuoteResponse,
  type IIBError,
  useErrorCodeTranslation
} from './insurance-in-a-box';
import {
  useDeniedOffer,
  useCancelledOffer,
  useEarlyAdopterOffer,
  useAmbassadorSignupOffer,
  useGetCampaignByCode,
  GetCampaignByCodeResponse
} from './sejfa';

export {
  mutations,
  queries,
  useDeniedOffer,
  useCancelledOffer,
  useErrorCodeTranslation,
  useEarlyAdopterOffer,
  useAmbassadorSignupOffer,
  useGetCampaignByCode,
  CalculateQuoteResponse,
  IIBError,
  GetTemplateResponse,
  CalculateQuoteRequest,
  CheckSimulationStatusRequest,
  ConvertQuoteToPolicyResponse,
  CheckSimulationStatusResponse,
  HomeData,
  type GetCampaignByCodeResponse
};
