import React from 'react';

import { Divider, TextInput, TextWithLabel } from '@components/index';
import { UserInfo } from '@core/types';
import { capitalizeAll, isEmail, isPhoneNumber } from '@lib/utils';

import InformationBlock from './InformationBlock';
import { useOnboardingTranslation } from '../translation';

type Props = {
  userInfo: UserInfo;
  givenName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  onChange: (changedValue: { key: keyof UserInfo; value: string }) => void;
};

export type RowData = {
  label: string;
  text: string | number;
  key: keyof UserInfo;
  dataType: 'text' | 'input';
  type: 'email' | 'text' | 'tel' | 'number';
  isValid?: boolean;
  errorMessage?: string;
  mandatory?: boolean;
};

const ContactInfoBlock = ({ userInfo, onChange }: Props) => {
  const t = useOnboardingTranslation();

  const rows: RowData[] = [
    {
      label: t('CONTACT_INFO.FIRST_NAME'),
      text: capitalizeAll(userInfo?.givenName ?? ''),
      key: 'givenName',
      dataType: 'text',
      type: 'text'
    },
    {
      label: t('CONTACT_INFO.LAST_NAME'),
      text: capitalizeAll(userInfo?.lastName ?? ''),
      key: 'lastName',
      dataType: 'text',
      type: 'text'
    },
    {
      label: t('CONTACT_INFO.EMAIL'),
      text: userInfo.email ?? '',
      key: 'email',
      dataType: 'input',
      type: 'email',
      isValid: isEmail.test(userInfo.email ?? ''),
      errorMessage: t('VALIDATION.EMAIL.MUST_BE_EMAIL'),
      mandatory: true
    },
    {
      label: t('CONTACT_INFO.PHONE_NUMBER'),
      text: userInfo.phoneNumber ?? '',
      dataType: 'input',
      key: 'phoneNumber',
      type: 'tel',
      isValid:
        !userInfo.phoneNumber || isPhoneNumber.test(userInfo.phoneNumber ?? ''),
      errorMessage: t('VALIDATION.PHONE.MUST_BE_PHONE_NUMBER')
    }
  ];

  return (
    <InformationBlock
      title={t('PRICE_SUGGESTION_SCREEN.CONTACT_DETAILS')}
      titleClassName="md:text-[40px]"
      containerClassName="gap-0"
    >
      {rows.map((row) =>
        row.key === 'email' || row.key === 'phoneNumber' ? (
          <div key={row.key} className="mt-8">
            <TextInput
              containerClassName="pb-4 mt-4"
              floatingLabel={row.label}
              value={row.text.toString()}
              type={row.type}
              onChange={(e) =>
                onChange({ key: row.key, value: e.target.value })
              }
              className="cursor-pointer text-2xl"
              floatingLabelClassName="text-xl"
              mandatory={row.mandatory}
            />
            <Divider className="bg-black" />
            {!row.isValid && (
              <span className="text-l border-l-errorBackground text-error ">
                {row.errorMessage}
              </span>
            )}
          </div>
        ) : (
          <React.Fragment key={row.key}>
            <TextWithLabel label={row.label} text={row.text.toString()} />
            <Divider />
          </React.Fragment>
        )
      )}
    </InformationBlock>
  );
};

export default ContactInfoBlock;
