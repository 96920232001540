import React, { useEffect, useRef } from 'react';

import { Modal } from '@components/index';
import { UserInfo } from '@core/types';
import { useSelectFromUserStore, useSimulateQuote } from '@features/onboarding';

import DVDLogoAnimation from './DVDLogoAnimation/DVDLogoAnimation';

type Props = {
  onEstimationComplete?: () => void;
  onEstimationFailed: () => void;
};

const EstimatingModal = ({
  onEstimationComplete,
  onEstimationFailed
}: Props) => {
  const user = useSelectFromUserStore((state: UserInfo) => state);

  const containerRef = useRef<HTMLDivElement>(null);

  const [dimensions, setDimensions] = React.useState<{
    height: number;
    width: number;
  }>({ height: 0, width: 0 });

  useSimulateQuote({
    age: user.age,
    postalCode: user.postalCode,
    numberOfResidents: user.residents,
    email: user.email,
    onSuccess: onEstimationComplete,
    onFail: onEstimationFailed
  });

  useEffect(() => {
    if (containerRef.current) {
      const height = containerRef.current.offsetHeight;
      const width = containerRef.current.offsetWidth;
      setDimensions({ height, width });
    }
  }, [containerRef]);

  return (
    <Modal className="md:py-0">
      <div
        ref={containerRef}
        className="relative flex h-full w-full flex-col overflow-hidden rounded-2xl bg-black font-bold md:py-0"
      >
        {dimensions.height > 0 && dimensions.width > 0 && (
          <DVDLogoAnimation
            height={dimensions.height}
            width={dimensions.width}
          />
        )}
      </div>
    </Modal>
  );
};

export default EstimatingModal;
