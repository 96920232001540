import { useCallback } from 'react';

import { useRouter } from 'next/router';

import storage from '@core/storage';
import {
  DISCOUNT_CODES as SAFETE_BY_CILIA_DISCOUNT_CODES,
  DISCOUNT_CODES as SafetyByCiliaDiscountCodes
} from '@features/safety-by-cilia';

type UseSignupSuccessResult = {
  handleSignupSuccess: () => void;
};

export const useSignupSuccess = (): UseSignupSuccessResult => {
  const router = useRouter();

  const handleSignupSuccess = useCallback(() => {
    const { campaignCode } = storage();

    if (
      SafetyByCiliaDiscountCodes.includes(
        campaignCode as (typeof SAFETE_BY_CILIA_DISCOUNT_CODES)[number]
      )
    ) {
      /* Default case -> navigate to onboarding success */
      router.push('/safety-by-cilia/success');
      return;
    }

    /* Default case -> navigate to onboarding success */
    router.push('/onboarding/success');
  }, [router]);

  return {
    handleSignupSuccess
  };
};
