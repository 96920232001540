import { useEffect } from 'react';

import {
  CheckSimulationStatusResponse,
  mutations,
  useErrorCodeTranslation
} from '@core/api';
import { useMessaging } from '@core/messaging';
import storage from '@core/storage';
import { useTranslation } from '@core/translation';
import { PriceData, UserInfo } from '@core/types';
import { isNumber } from '@lib/utils';

import useCampaignDiscount from './useCampaignDiscount';
import {
  updatePriceStore,
  useSelectFromPriceStore,
  useSelectFromUserStore
} from '../stores';

const useDiscountCode = (onSuccess?: () => void) => {
  const user = useSelectFromUserStore((state: UserInfo) => state);
  const priceStore = useSelectFromPriceStore((state: PriceData) => state);
  const { addMessage } = useMessaging();
  const tError = useTranslation('ERROR');
  const { getErrorTranslation } = useErrorCodeTranslation();
  const {
    getDiscountCode,
    discountCode,
    campaignCode,
    resetCampaignDiscountState
  } = useCampaignDiscount();

  const { saveCampaignCode, removeCampaignCode } = storage();

  const handleSimulationSuccess = (
    data: CheckSimulationStatusResponse & {
      result: {
        discountCode?: string | undefined;
        campaignCode?: string | undefined;
      };
    }
  ) => {
    if (data?.result) {
      const {
        monthlyPremium,
        premiumAmount,
        currency,
        discountMonthlyPremium,
        discountPremiumAmount,
        discountCode,
        campaignCode,
        isValidSimulation
      } = data.result;

      const priceData: PriceData = {
        ...priceStore,
        price: premiumAmount,
        monthlyPrice: monthlyPremium,
        discountMonthlyValue: discountMonthlyPremium || null,
        discountValue: discountPremiumAmount || null,
        currency,
        discountCode: isNumber(discountPremiumAmount)
          ? discountCode
          : undefined,
        isEstimating: false,
        campaignCode
      };
      updatePriceStore('updatePrice')({ ...priceData });

      if (campaignCode) {
        saveCampaignCode(campaignCode);
      } else {
        removeCampaignCode();
      }

      if (isValidSimulation) {
        if (discountCode && !isNumber(discountPremiumAmount)) {
          updateStoreDiscountCode(undefined);
          removeCampaignCode();
          addMessage({
            message: tError('INVALID_DISCOUNT_CODE'),
            payload: tError('INVALID_DISCOUNT_CODE')
          });
        } else {
          onSuccess?.();
        }
        return;
      }
    }
  };

  const { mutate, isLoading, isSuccess } = mutations.quotes.useSimulateQuote();

  const updateStoreDiscountCode = (discountCode?: string) => {
    updatePriceStore('updatePrice')({
      discountCode
    });
  };

  useEffect(() => {
    if (discountCode) {
      mutate(
        {
          age: user.age,
          postalCode: user.postalCode,
          numberOfResidents: user.residents,
          discountCode: discountCode || null
        },
        {
          onSuccess: (data) =>
            handleSimulationSuccess({
              ...data,
              result: {
                ...data.result,
                discountCode: discountCode || undefined,
                campaignCode: campaignCode || undefined
              }
            }),
          onError: (error) => {
            addMessage({
              message: getErrorTranslation(error?.data?.code),
              payload: error
            });
          }
        }
      );
    }
  }, [discountCode, campaignCode]);

  const setDiscountCode = async (campaignCode?: string) => {
    if (campaignCode) {
      await getDiscountCode(campaignCode);
    } else {
      mutate(
        {
          age: user.age,
          postalCode: user.postalCode,
          numberOfResidents: user.residents
        },
        {
          onSuccess: (data) => {
            resetCampaignDiscountState();
            handleSimulationSuccess({
              ...data,
              result: {
                ...data.result
              }
            });
          },
          onError: (error) => {
            resetCampaignDiscountState();
            addMessage({
              message: getErrorTranslation(error?.data?.code),
              payload: error
            });
          }
        }
      );
    }
  };

  return {
    setDiscountCode,
    isSuccess,
    isLoading
  };
};

export default useDiscountCode;
